var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.theme.showTheme
    ? _c(
        "span",
        [
          _c("vab-icon", {
            attrs: { icon: "brush-2-line" },
            on: { click: _vm.handleOpenTheme },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }