var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.theme.showFullScreen
    ? _c("vab-icon", {
        attrs: {
          icon: _vm.isFullscreen ? "fullscreen-exit-fill" : "fullscreen-fill",
        },
        on: { click: _vm.click },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }