var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vab-header" }, [
    _c(
      "div",
      { staticClass: "vab-main" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 6 } }, [_c("vab-logo")], 1),
            _c("el-col", { attrs: { span: 18 } }, [
              _c(
                "div",
                { staticClass: "right-panel" },
                [
                  "horizontal" === _vm.layout
                    ? _c(
                        "el-menu",
                        {
                          attrs: {
                            "active-text-color":
                              _vm.variables["menu-color-active"],
                            "background-color":
                              _vm.variables["menu-background"],
                            "default-active": _vm.activeMenu,
                            "menu-trigger": "hover",
                            mode: "horizontal",
                            "text-color": _vm.variables["menu-color"],
                          },
                        },
                        [
                          _vm._l(_vm.handleRoutes, function (route, index) {
                            return [
                              route.meta && !route.meta.hidden
                                ? _c("vab-menu", {
                                    key: index + route.name,
                                    attrs: { item: route, layout: _vm.layout },
                                  })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("vab-error-log"),
                  _c("vab-search"),
                  _c("vab-notice"),
                  _c("vab-full-screen"),
                  _c("vab-language"),
                  _c("vab-theme"),
                  _c("vab-refresh"),
                  _c("vab-avatar"),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }