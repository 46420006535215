var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { mode: "out-in", name: "fade-transform" } },
    [
      _c(
        "keep-alive",
        { attrs: { include: _vm.keepAliveNameList, max: _vm.keepAliveMaxNum } },
        [_c("router-view", { key: _vm.routerKey })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }