var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-breadcrumb",
    { staticClass: "vab-breadcrumb", attrs: { separator: ">" } },
    [
      _vm._l(_vm.breadcrumbList, function (item, index) {
        return [
          _c(
            "el-breadcrumb-item",
            { key: index, attrs: { to: { path: item.redirect } } },
            [
              item.meta && item.meta.icon
                ? _c("vab-icon", { attrs: { icon: item.meta.icon } })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.translateTitle(item.meta.title)) + " "),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }