var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.itemOrMenu.meta && _vm.itemOrMenu.meta.levelHidden
    ? _c(
        "div",
        [
          _vm._l(_vm.itemOrMenu.children, function (route) {
            return [_c("vab-menu", { key: route.path, attrs: { item: route } })]
          }),
        ],
        2
      )
    : _c(
        "el-submenu",
        {
          ref: "subMenu",
          attrs: { index: _vm.itemOrMenu.path, "popper-append-to-body": false },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _vm.itemOrMenu.meta && _vm.itemOrMenu.meta.icon
                ? _c("vab-icon", {
                    attrs: {
                      icon: _vm.itemOrMenu.meta.icon,
                      "is-custom-svg": _vm.itemOrMenu.meta.isCustomSvg,
                      title: _vm.translateTitle(_vm.itemOrMenu.meta.title),
                    },
                  })
                : _vm._e(),
              _c(
                "span",
                {
                  attrs: {
                    title: _vm.translateTitle(_vm.itemOrMenu.meta.title),
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.translateTitle(_vm.itemOrMenu.meta.title)) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._t("default"),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }