var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "logo-container",
      class: {
        ["logo-container-" + _vm.theme.layout]: true,
      },
    },
    [
      _c("span", { staticClass: "logo" }, [
        _c("img", {
          staticStyle: { width: "40px", height: "40px" },
          attrs: { alt: "", src: _vm.logo },
        }),
      ]),
      _c(
        "span",
        {
          staticClass: "title",
          class: { "hidden-xs-only": _vm.theme.layout === "horizontal" },
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }