var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vab-app-main" },
    [_c("section", [_c("vab-router-view")], 1), _c("vab-footer")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }